import styled from 'styled-components';

export const ContainerForm = styled.div`
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #d66464;

    margin-top: 42px;
    margin-bottom: 64px;
  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .btn-primary {
    border-color: #d66464;
    background: #d66464;
    border-radius: 12px;
  }

  input {
    background: #ebebeb;
    box-shadow: 3px 5px 4px #c9c9c9;
    border-radius: 6px;
    border: 0;
  }

  textarea {
    background: #ebebeb;
    box-shadow: 3px 5px 4px #c9c9c9;
    border-radius: 6px;
    border: 0;
  }

  select {
    background: #ebebeb;
    box-shadow: 3px 5px 4px #c9c9c9;
    border-radius: 6px;
    border: 0;
  }
`;

export const ContainerModal = styled.div`
  h5 {
    margin-bottom: 37px;
  }
  .btn-primary {
    border-color: #d66464;
    background: #d66464;
    border-radius: 12px;
  }

  [aria-label]:hover::after {
    background: #d66464;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-footer {
    border-top: 0;
  }
`;

export const UserInfo = styled.span`
  font-size: 13px;
`;

export const Separator = styled.hr`
  margin-bottom: -20px;
`;
