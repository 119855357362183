import axios from "axios";
import { getApiToken } from "./decodeJWT";

const http = axios.create({
  baseURL:
    String(process.env.REACT_APP_BASE_URL) +
    `/notification/`,
  // baseURL: 'http://localhost:3013/notification',
  timeout: 30000,
});

let token = getApiToken();

async function sendEmail(data: any) {
  try {
    const response = await http.post("/", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message);
  }
}

async function sendSolicitationEmail(data: any) {
  try {
    const response = await http.post("sendStatusSolicitationEmail", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message);
  }
}

async function sendActivateEmail(data: any) {
  // console.log(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_NOTIFICATION_PORT+`/notification/`, data)
  try {
    const response = await http.post("/activateAccount", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message);
  }
}

async function sendRecoveryEmail(data: any) {
  try {
    const response = await http.post("/recoveryAccount", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message);
  }
}

async function sendInviteEmail(data: any) {
  // console.log(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_NOTIFICATION_PORT+`/notification/`, data)
  try {
    const response = await http.post("/trainingInvite", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(response)
    return response;
  } catch (error) {
    // console.log(error.message);
    return error;
  }
}

async function sendGenericEmail(data: any) {
  // console.log(data);
  try {
    const response = await http.post("/sendGenericEmail", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message);
    return error;
  }
}

export {
  sendEmail,
  sendActivateEmail,
  sendRecoveryEmail,
  sendInviteEmail,
  sendGenericEmail,
  sendSolicitationEmail,
};
