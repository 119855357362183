import styled from 'styled-components';

export const ChatContainer = styled.div`
  margin-top: 50px;
`;

export const MessagesContainer = styled.div`
  flex: 1;
  padding: 10px;

  border-radius: 8px;
  background: #ebebeb;

  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
`;

export const MessageContainer = styled.div<{ isOwnerMessage: boolean }>`
  display: flex;
  flex-direction: ${({ isOwnerMessage }) =>
    isOwnerMessage ? 'row-reverse' : 'row'};
  margin-bottom: 10px;
`;

export const Message = styled.div<{ isOwnerMessage: boolean }>`
  max-width: 60%;
  font-size: 13px;
  text-align: ${({ isOwnerMessage }) => (isOwnerMessage ? 'end' : 'start')};
  margin-bottom: 20px;

  span {
    font-size: 11px;
  }
`;

export const MessageBubble = styled.div<{ isOwnerMessage: boolean }>`
  background-color: ${({ isOwnerMessage }) =>
    isOwnerMessage ? '#d9fdd3' : '#ffffff'};
  padding: 8px;
  border-radius: 8px;
  word-wrap: break-word;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-bottom: 5px;
`;

export const Title = styled.label`
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 10px;

  background: #ebebeb;
  border-radius: 8px;
`;

export const Input = styled.input`
  flex: 1;
  padding: 10px;

  background: #ebebeb;
  border: none;
`;

export const ContentReply = styled.div`
  .btn-primary {
    border-color: #d66464;
    background: #d66464;
    border-radius: 12px;
    width: 140px;
  }

  .btn-cancel {
    border-color: #e5e5e5;
    background: #ffffff;
    border-radius: 12px;
    width: 140px;
  }
`;
