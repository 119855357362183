import { useEffect, useState } from 'react';
import { Header } from '../../components/header/header';
import { getChatById } from '../../services/chat-service';
import { ChatMessage } from '../../components/chat';
import { IMessage } from '../../interfaces/IMessage';

export const Chat = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [room, setRoom] = useState<string>('');

  const dataSolicitation = localStorage.getItem('solicitation');
  const solicit: any = JSON.parse(dataSolicitation!);

  const handleMessages = (concatIds: string) => {
    getChatById(concatIds).then((response: any) => {
      setMessages(response.data.messages);
    });
  };

  useEffect(() => {
    const concatIds = solicit?.institutionId?.concat('_').concat(solicit._id);
    setRoom(concatIds);
    handleMessages(concatIds);
  }, [solicit?._id, solicit?.institutionId]);

  return (
    <>
      <Header title={'Canal de Atendimento'}>
        <p>
          Protocolo: <strong>{solicit?.numberProtocol}</strong>
        </p>
      </Header>
      <ChatMessage
        solicit={solicit}
        userData={{ _id: solicit?._id, name: solicit?.nome }}
        protocol={solicit?.numberProtocol}
        room={room}
        chatData={messages}
        relatedEntities={{
          source: solicit?._id,
          sourceName: solicit?.nome,
          target: solicit?.institutionId,
        }}
        chatIsOpened={true}
      />
    </>
  );
};
