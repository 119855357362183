import api from './api';
//import { getAxiosConnection } from './axios-connection';

export const getInstitutionsUrl = () => {
  return (
    process.env.REACT_APP_BASE_URL +
    (process.env.REACT_APP_INSTITUTIONS_PORT! || "") +
    `/institutions/`
  );
  // return `http://localhost:3002/institutions/`;
};

export const getReportsUrl = () => {
  // return `https://0m6r0rf6bd.execute-api.us-east-1.amazonaws.com/dev/institutions/`
  return (
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_INSTITUTIONS_PORT! +
    `/dxreports/`
  );
  // return `https://6rg9ylvwn4.execute-api.sa-east-1.amazonaws.com/hml-auth/institutions`
  // return `http://localhost:3002/institutions/`;
};

export const getInstitutionById = async (institutionId: string) => {
  return api
    .get(getInstitutionsUrl() + institutionId)
    .then((response: any) => {
      return response.data.data[0];
    });
};
