// import api from './api';
import {getAxiosConnection} from "../services/axios-connection";

// export const getUserUrl = () => {
//   // return `https://0m6r0rf6bd.execute-api.us-east-1.amazonaws.com/dev/users/`
//   return process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USERS_PORT! + `/users/`;
//   // return `http://localhost:3001/users/`;
// }

export function getUsersByInstitutionId(id: string) {
  return new Promise(async resolve => {
    getAxiosConnection("Usuários").get(`users/getUsersByInstitutionId/${id}`).then((res: any) => {
      return resolve(res.data);
    });
  });
}
