/* eslint-disable jsx-a11y/alt-text */
import { ReactNode } from 'react';
import { Info, Logo, Title } from './style';
import Popover from 'devextreme-react/popover';
import pkg from '../../../package.json';

type headerProps = {
  title?: string;
  children?: ReactNode;
  institution?: any;
};

export const Header = ({ title, children, institution }: headerProps) => {
  const image: string = institution ? institution : "https://cartorioslgpd.com.br/static/media/logoCompletaLGPD.49eb9c5f1db0d85ac601.png"
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 col-md-2 d-flex justify-content-center">
          <Logo>
            <img src={image} height={100}/>
          </Logo>
        </div>
        <div id="policies-caption" className="col-12 col-md-6 text-center">
          <Title>{title}
          <Popover
            target="#policies-caption" showEvent="mouseenter" hideEvent="mouseleave" position="bottom"
          >
          <span>{`Versão: ${pkg.version}`}</span>
          </Popover>
          </Title>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-end">
          <Info>{children}</Info>
        </div>
      </div>
    </div>
  );
};
