import { Route, Routes } from 'react-router-dom';
import { Solicitations } from '../pages/solicitations';
import { Interactions } from '../pages/interactions';
import { Chat } from '../pages/chat';
import { PageError } from '../pages/404';

export const Router = () => {
  return (
    <Routes>
      <Route index path="/:token" element={<Solicitations />} />
      <Route path="interactions/:token" element={<Interactions />} />
      <Route path="solicitation/:token" element={<Chat />} />
      <Route path="*" element={<PageError />} />
    </Routes>
  );
};
