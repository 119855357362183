import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  margin-top: 60px;
  padding: 100px;

  border: 1px solid #e5e5e5;
  border-radius: 12px;

  @media (min-width: 320px) and (max-width: 425px) {
    margin-top: 10px;
    padding: 20px;
  }
`;

export const List = styled.ul`
  list-style: none;

  li {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #232323;
    padding-bottom: 10px;
  }
`;

export const Item = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #232323;
  padding-bottom: 10px;

  cursor: pointer;
`;

export const ViewFile = styled.a`
  color: #232323;
  text-decoration: none;
  cursor: pointer;
`;

export const DownloadFile = styled.a`
  color: #232323;
  text-decoration: none;
  cursor: pointer;
`;
