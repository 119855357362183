import styled from 'styled-components';

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20%;
`;

export const Title = styled.h1`
  font-size: 70px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
`;

export const Message = styled.h3`
  margin-top: 50px;
  font-size: 30px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
`;
