import jwt_decode from 'jwt-decode';

export function decodeToken(token: string): any {
  let decodedToken;
  try {
    decodedToken = jwt_decode(token);
  } catch (e) {
    console.error(e);
  }
  return decodedToken;
}
