import axios from "axios";
/* import {sendSqsMessageBatch} from "../services/aws-sqs-service";
import { generateId } from "../utils/common-functions";
import { decodeUserData } from "./decodeJWT"; */

export const getAxiosConnection = (params: string) => {

    const connection = axios.create({
        timeout: 0,
        decompress: true,
        baseURL: `${process.env.REACT_APP_BASE_URL}`,
    })

    // const sendSqsMessage = async(body:any) => {
    //     await sendSqsMessageBatch("send-logs-to-dynamo", body);
    // }

    const token = localStorage.getItem('token')! || 0;
    // const loggedUser: any = decodeUserData();

    connection.interceptors.request.use(async (config: any) => {
        config.headers.Authorization = token;
        return config;
    })

    connection.interceptors.response.use(async(config: any) => {
        
        // const dataToLog = {
        //     moduleName: params,
        //     status: config.status,
        //     response: config.data,
        //     url: config.config.url,
        //     payload: config.config.data,
        //     method: config.config.method
        // }

        // const body = {
        //     id: generateId(),
        //     institutionId: loggedUser?.institution,
        //     userId: loggedUser?._id,
        //     logDateTime: new Date(),
        //     data: dataToLog
        // }
        
        // if (token && params !== "Histórico de logs") {
        //     await sendSqsMessage(body);
        // };

        return config;
    })

    return connection;
}