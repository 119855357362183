import axios from 'axios';
import { getApiToken } from './decodeJWT';

let token = getApiToken();

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  // baseURL: `https://6rg9ylvwn4.execute-api.sa-east-1.amazonaws.com/hml`,
  headers: {
    Authorization: token
  }
});

export default api;
