import { useState } from 'react';
import { Header } from '../../components/header/header';
import { SearchCustomer } from './styles';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { limpaCPF } from '../../utils/cleanCPF';
import { Badge, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

export const Interactions = () => {
  const [cpf, setCpf] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [show, setShow] = useState(false);
  const [solicitations, setSolicitations] = useState<any>([]);
  const navigate = useNavigate();
  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user')!);

  const cpfMask = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleGetSolicitation = async (cpf: string) => {
    const cpfClean = limpaCPF(cpf);
    if (cpfClean === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'informe um CPF',
      });
    } else {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BASE_URL}/support/customers`,
        );
        const solicitations = response.data;
        const filtered = solicitations?.filter((item: any) => {
          if (!Array.isArray(item.status)) {
            item.status = [String(item.status)];
            item.status = item.status[item.status.length - 1];
          } else {
            console.log('cpf', item.status);
            item.status = item.status[item.status.length - 1];
          }
          return (
            item.cpf.includes(cpfClean) &&
            item.institutionId.includes(user?.institution) &&
            item.status !== 'CANCELADO' 
          );
        });
        if (filtered.length > 0) {
          setSolicitations(filtered);
          openModal();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Você não tem atendimentos em andamento.',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setCpf(cpfMask(value));
  };

  const handleSelectSolicitation = (solicitation: any) => {
    localStorage.setItem('solicitation', JSON.stringify(solicitation));
    navigate(`/solicitation/${token}`);
  };

  const validateCpf = () => {
    const cleanedCpf = cpf.replace(/\D/g, '');
    if (cleanedCpf.length !== 11) {
      setIsValid(false);
      return;
    }
    if (/^(\d)\1+$/.test(cleanedCpf)) {
      setIsValid(false);
      return;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanedCpf.charAt(i)) * (10 - i);
    }
    let firstDigit = 11 - (sum % 11);
    if (firstDigit > 9) {
      firstDigit = 0;
    }
    if (parseInt(cleanedCpf.charAt(9)) !== firstDigit) {
      setIsValid(false);
      return;
    }
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanedCpf.charAt(i)) * (11 - i);
    }
    let secondDigit = 11 - (sum % 11);
    if (secondDigit > 9) {
      secondDigit = 0;
    }
    if (parseInt(cleanedCpf.charAt(10)) !== secondDigit) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  };

  return (
    <>
      <Header title={'Canal de atendimento'} />

      <SearchCustomer className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4 cpf-container">
            <label htmlFor="">CPF:</label>
            <input
              type="text"
              className="form-control text-center"
              maxLength={14}
              name="cpf"
              value={cpf}
              onChange={handleChange}
              onBlur={validateCpf}
            />
            {!isValid && (
              <span className={'badge text-bg-danger w-100'}>
                O CPF informado não é válido.
              </span>
            )}
          </div>
          <div className="col-md-4 offset-md-4 d-flex justify-content-end">
            <button
              disabled={!isValid}
              className="btn btn-primary"
              onClick={() => handleGetSolicitation(cpf)}
            >
              Entrar
            </button>
          </div>
        </div>
      </SearchCustomer>

      {/* Modal com a lista de solicitações encontradas. */}
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Lista de solicitações</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {solicitations.map((solicitation: any, index: number) => (
            <Badge
              bg="warning"
              key={index}
              style={{
                width: '100%',
                cursor: 'pointer',
                color: '#000',
              }}
              onClick={() => handleSelectSolicitation(solicitation)}
            >
              Protocolo: {solicitation.numberProtocol} | {solicitation.nome} |
              Data de Abertura: {solicitation.createdAt}
            </Badge>
          ))}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
