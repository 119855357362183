import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     outline: 0;
   }

   body {
     background: #fff;
     color: #232323;
     font-family: "Poppins", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   }

   body, input, button {
     font-family: 'Poppins', sans-serif;
     font-size: 16px;
   }

   h1, h2, h3, h4, h5, h6, strong {
     font-weight: 700;
   }

   button {
     cursor: pointer;
   }
`;
