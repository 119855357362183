import axios from 'axios';
import { getAxiosConnection } from './axios-connection';
import { getApiToken } from './decodeJWT';

/* const getUrl = () => {
  // return `https://0m6r0rf6bd.execute-api.us-east-1.amazonaws.com/dev/files/`
  // return `https://6rg9ylvwn4.execute-api.sa-east-1.amazonaws.com/hml/files/`;
  return (
    process.env.REACT_APP_BASE_URL +
    // process.env.REACT_APP_FILES_PORT! +
    `/files/`
  );
  // return `http://localhost:3004/files/`
}; */

let token = getApiToken();

export interface IUploadFileParams {
  bucketName: string;
  fileKey: string;
  fileData: File;
  fileName?: string;
  contentType?: string;
}

export interface IDowloadFilesParams {
  bucketName: string;
  key: string;
  fileName: string;
}

export function uploadFilesToS3(params: IUploadFileParams) {
  return new Promise(async (resolve, reject) => {
    const fname = params.fileName || params.fileData.name;
    const cType = params.contentType || params.fileData.type;
    let getSignedUrlParams = {
      bucket: params.bucketName,
      key: params.fileKey + fname,
      contentType: cType,
    };
    getAxiosConnection("Serviço de arquivos")
      .post(`/files/getUrlToUploadFile`, getSignedUrlParams, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            axios
              .put(response.data.signedUrl, e.target?.result, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': `${cType};base64`,
                  // Authorization: token,
                },
              })
              .then(putRes => {
                resolve(putRes);
              })
              .catch((err: any) => {
                // console.log(err)
                reject(err);
              });
          };
          reader.onerror = function (error: any) {
            // console.log('Error: ', error);
          };
          reader.readAsArrayBuffer(params.fileData);
        }
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function downloadFileFromS3(params: IDowloadFilesParams) {
  return new Promise(async (resolve, reject) => {
    let getSignedUrlParams = {
      bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
      key: params.key,
    };
    console.log("getSignedUrlParams: ", getSignedUrlParams, token);
    getAxiosConnection("Serviço de arquivos")
      .post(`/files/getUrlToDownloadFile`, getSignedUrlParams, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          axios
            .get(response.data.signedUrl, {
              responseType: 'blob',
              headers: {
                // Authorization: token,
              },
            })
            .then(putRes => {
              const url = window.URL.createObjectURL(new Blob([putRes.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', params.fileName);
              document.body.appendChild(link);
              link.click();
              resolve(putRes);
            })
            .catch((err: any) => {
              console.log(err);
              reject(err);
            });
        }
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function getFileFromS3(params: IDowloadFilesParams) {
  return new Promise(async (resolve, reject) => {
    let paramsForm = {
      bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
      key: params.key,
    };
    getAxiosConnection("Serviço de arquivos")
      .post(`/files/getUrlToDownloadFile`, paramsForm, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: token,
        },
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          resolve(response?.data.signedUrl);
        }
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function deleteFileFromS3(params: IDowloadFilesParams) {
  return new Promise(async (resolve, reject) => {
    let getDeleteParams = {
      bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
      key: params.key,
    };
    getAxiosConnection("Serviço de arquivos")
      .post(`/files/deleteFileFromS3`, getDeleteParams, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: token,
        },
      })
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function displayFileFromS3(params: IDowloadFilesParams) {
  return new Promise(async (resolve, reject) => {
    let getSignedUrlParams = {
      bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
      key: params.key,
    };

    getAxiosConnection("Serviço de arquivos")
      .post(`/files/getUrlToDownloadFile`, getSignedUrlParams, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: token,
        },
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          axios
            .get(response.data.signedUrl, {
              responseType: 'text',
              headers: {
                // Authorization: token,
              },
            })
            .then(async putRes => {
              if (putRes.data.includes('data:image/png;base64')) {
                fetch(putRes.data)
                  .then(res => resolve(res.blob()))
                  .then(console.log);
              } else {
                const blobRes: any = await axios.get(response.data.signedUrl, {
                  responseType: 'blob',
                });
                resolve(blobRes.data);
                const url = window.URL.createObjectURL(blobRes.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.target = '_blank';
                a.href = url;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              }
            })
            .catch((err: any) => {
              console.log(err);
              reject(err);
            });
        }
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function getSignedUrlFromS3(params: IDowloadFilesParams) {
  return new Promise(async (resolve, reject) => {
    let getSignedUrlParams = {
      bucket: params.bucketName,
      key: params.key,
    };
    getAxiosConnection("Serviço de arquivos")
      .post(`/files/getUrlToDownloadFile`, getSignedUrlParams, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: token,
        },
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          resolve(response?.data.signedUrl);
        }
      })
      .catch((ex: any) => {
        reject(ex);
      });
  });
}

export function changeFileName(fileName: string) {
  const date = new Date();
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  console.log("Filename: ", fileName);
  
  const fileNameParts = fileName.split('.');
  const extension = fileNameParts.pop();
  const baseName = fileNameParts.join('.');

  const newBaseName = `${baseName} ${dateString}`;
  const newFileName = `${newBaseName}.${extension}`;          
  return newFileName;
}
