import styled from 'styled-components';

export const Logo = styled.div`
  justify-content: center;
  align-items: center;
  align-content: flex-start;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #d66464;
`;

export const Info = styled.div`
  flex-direction: column;
  align-items: center;
  align-content: center;

  p {
    font-style: normal;
    font-size: 16px;
  }
`;
