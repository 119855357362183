import axios from 'axios';
import { getAxiosConnection } from "../services/axios-connection";
import api from './api';
import { getApiToken } from './decodeJWT';

let token = getApiToken();

/* const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/report/`,
  // baseURL: 'http://localhost:3019/report',
  timeout: 30000,
}); */

const getUrl = () => {
  return process.env.REACT_APP_TEMPLATE_API_BASE_URL + `/templates/`;
  // return `https://6rg9ylvwn4.execute-api.sa-east-1.amazonaws.com/hml/templates/`
  // return process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_PORT + `/templates/`;
  // return `http://localhost:3023/templates/`;
}

const downloadPDF = (pdf: any, fileName: any) => {
  const linkSource = `data:application/pdf;base64,${pdf.signedUrl}`;
  const downloadLink = document.createElement('a');
  // const fileName = 'relatório.pdf';

  downloadLink.target = "_blank"
  downloadLink.href = pdf.signedUrl;
  downloadLink.download = fileName;
  downloadLink.click();
};

export async function generateReport(data: any, templateUrl: any, fileName = "relatório.pdf", docType = "pdf") {
  try {
    const response = await api.post(
      `report/generateReport?templateUrl=${templateUrl}&docType=${docType}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    downloadPDF(response.data, fileName);
    return response;
  } catch { }
}

export async function generateUrlReport(data: any, templateUrl: any) {
  try {
    const response = await api.post(
      `report/generateReport?templateUrl=${templateUrl}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch { }
}

export async function exportReport(dataReport: any, templateConfig: any) {
  try {
    const params = {
      bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
      key: `templates/${templateConfig.modelTemplate}`
    };

    const response = await axios({
      method: 'post',
      url: `${getUrl()}generateTemplate`,
      data: {
        dataReport,
        params,
        fileName: templateConfig.fileName,
        fileType: templateConfig.fileType
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      
    });

    if (response.data?.base64) {
      // Convert base64 to Blob
      const byteCharacters = atob(response.data.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      // Create Object URL
      const url = window.URL.createObjectURL(blob);

      // Create download link and click it
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `${templateConfig.fileName}.docx`;
      downloadLink.click();

      // Revoke Object URL
      window.URL.revokeObjectURL(url);
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = response.data.pdfFile.url;
      downloadLink.download = `${templateConfig.fileName}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
}
