import React, { useEffect, useMemo } from 'react';
import { GlobalStyle } from './styles/globalStyles';
import { Router } from './routes';
import { useLocation } from 'react-router-dom';
import { decodeToken } from './utils/decodeToken';

function App() {
  let _uri = useLocation().pathname;

  _uri = _uri.replace('/%22', '').replace('%22', '');

  const user = useMemo(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    let token = '';
    let route = _uri.substring(0, 14);

    if (route === '/interactions/') {
      token = _uri.substring(14);
    } else if (route === '/solicitation/') {
      token = _uri.substring(14);
    } else {
      let newToken = _uri.substring(0);
      token = newToken;
    }

    token = token.startsWith("/") ? token.split('/')[1] : token;

    console.log("Token -> ", token);

    if (token) {
      console.log("Token -> ", token)
      const decode = decodeToken(token);
      localStorage.setItem('token', token!);
      localStorage.setItem('user', JSON.stringify(decode));
    }
  }, [_uri]);

  useEffect(() => {}, [user]);

  return (
    <>
      <GlobalStyle />
      <div className="container">
        <Router />
      </div>
    </>
  );
}

export default App;
