import styled from 'styled-components';

export const SearchCustomer = styled.div`
  margin-top: 300px;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #232323;
  }

  input {
    font-size: 38px;
    border: 0;
  }

  button {
    margin-top: 43px;
  }

  .btn-primary {
    border-color: #d66464;
    background: #d66464;
    border-radius: 12px;
    width: 140px;
  }

  .cpf-container {
    border-bottom: 3px solid #323232;
  }
`;
