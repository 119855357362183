/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { IPolicies } from '../../interfaces/IPolicies';
import api from '../../services/api';
import { getFileFromS3 } from '../../services/file-service';
import { ViewIcon } from '../icons/viewIcon';
import { Container, ViewFile } from './styles';

export const Policies = () => {
  const [policies, setPolicies] = useState<IPolicies[]>([]);
  const user = JSON.parse(localStorage.getItem('user')!);

  const viewFile = async (file: string) => {
    let params: any = {
      key: `library/${user?.institution}/Políticas/${file}`,
      fileName: file,
    };
    getFileFromS3(params)
      .then(res => {
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = String(res);
        a.click();
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 503) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Arquivo não encontrado na base de dados.',
          });
        }
      });
  };

  const isArchivedStatus = (status: any) => {
    return (
      (Array.isArray(status) &&
        (status[status.length - 1] === 'ARQUIVADO' ||
          status[status.length - 1] === 'arquivado')) ||
      (typeof status === 'string' &&
        (status === 'ARQUIVADO' || status === 'arquivado')) ||
      (typeof status === 'boolean' && status === true)
    );
  };

  useEffect(() => {
    api
      .get(
        `/library/getAllFilesLibraryByInstitutionId?institutionId=${user?.institution}&type=Pública`,
      )
      .then(response => {
        const itens = response.data;
        const policiesUnarchiveds = itens?.filter((file: any) => {
          return (
            file.category === 'Políticas' &&
            (isArchivedStatus(file.status) === false || null || undefined)
          );
        });
        setPolicies(policiesUnarchiveds);
      });
  }, []);

  return (
    <Container>
      <table className="table table-responsive">
        <tbody>
          {policies.map((item, index) => (
            <tr key={index}>
              <td width={'90%'}>
                <strong>{item.description}</strong>
              </td>
              <td width={'5%'}>
                {item.files.current && item.files ? (
                  item?.files?.current?.map((item: any, index: number) => (
                    <ViewFile key={index} onClick={() => viewFile(item?.name)}>
                      <ViewIcon width={22} height={15} />
                    </ViewFile>
                  ))
                ) : item.files.current ? (
                  <></>
                ) : item.files ? (
                  item?.files.map((item: any, index: number) => (
                    <ViewFile key={index} onClick={() => viewFile(item)}>
                      <ViewIcon width={22} height={15} />
                    </ViewFile>
                  ))
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
