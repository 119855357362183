import { ContainerPage, Message, Title } from './styles';

export const PageError = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 text-center">
          <ContainerPage>
            <Title>
              Erro ao processar a<br />
              requisição
            </Title>
            <Message>Por favor, faça a leitura do qrcode novamente.</Message>
          </ContainerPage>
        </div>
      </div>
    </div>
  );
};
