import api from "./api";

export async function getChatById(id: any) {
  try {
    const response = await api.get(`/chat/getChatById/${id}`);
    return response;
  } catch (err) {
    console.log(err)
  }
}

export async function registerChat(messages: any, roomId: any) {
  try {
    const response = await api.post('/chat/register', {
      messages,
      roomId,
    });
    return response;
  } catch {}
}
