/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { Header } from '../../components/header/header';
import { Policies } from '../../components/policies-list/policies';
import { IMessage } from '../../interfaces/IMessage';
import api from '../../services/api';
import { registerChat } from '../../services/chat-service';
import { getFileFromS3 } from '../../services/file-service';
import { getUsersByInstitutionId } from '../../services/user-service';
import { limpaCPF } from '../../utils/cleanCPF';
import { ContainerForm, Separator, UserInfo } from './styles';
import { useNavigate } from 'react-router-dom';
import { generateUrlReport } from '../../services/report-service';
import { sendSolicitationEmail } from '../../services/notification-service';
import { getInstitutionById } from '../../services/institutions-service';

let socket: any = null;

export const Solicitations = () => {
  const [numberProtocol, setNumberProtocol] = useState('');
  const [, setMessages] = useState<IMessage[]>([]);
  const [show, setShow] = useState(false);
  const [dpo, setDpo] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [complete, setComplete] = useState(false);
  const [cpf, setCpf] = useState('');
  const [institution, setInstitutions] = useState<string | undefined>(
    undefined,
  );

  const cpfMask = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  const user = JSON.parse(localStorage.getItem('user')!);
  const token = localStorage.getItem('token'!) ?? '';

  const verProtocolo = () => setShow(!show);

  const createSocket = () => {
    try {
      socket = new W3CWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}`,
        '',
        '',
        {},
        { closeTimeout: 3000, disableNagleAlgorithm: false },
      );
    } catch (err) {
      socket = null;
      console.log(err);
    }
  };

  const handleGenerateProtocol = () => {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    setNumberProtocol(`${year}${month}${day}${hour}${minute}`);
  };

  const getManagersData = async () => {
    const allUsers: any = await getUsersByInstitutionId(user?.institution);
    const dpoByInstitution = allUsers.filter((usr: any) => {
      return (
        (usr.role === 2 || usr.role === 'Encarregado de dados') && usr.active
      );
    });
    console.log(allUsers);
    setDpo(dpoByInstitution);
  };

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date: Date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  const handleSendMessage = (
    authorId: string,
    authorName: string,
    institution: string,
    mensagem: string,
  ) => {
    const messageDataToSave = {
      relatedEntities: {
        source: authorId,
        sourceName: authorName,
        target: institution,
      },
      author: {
        _id: authorId,
        name: authorName,
      },
      message: mensagem,
      time: new Date().toLocaleString(),
      dateTime: new Date(),
    };

    const messageDataToSend = {
      action: 'send_message',
      data: {
        relatedEntities: {
          source: authorId,
          sourceName: authorName,
          target: institution,
        },
        author: authorName,
        message: mensagem,
        time: new Date().toLocaleString(),
      },
    };
    setTimeout(() => {
      socket.send(JSON.stringify(messageDataToSend));
    }, 11);
    setMessages(prevMessages => [
      ...prevMessages,
      {
        author: messageDataToSave.author,
        message: messageDataToSave.message,
        relatedEntities: messageDataToSave.relatedEntities,
        time: messageDataToSave.time,
        dateTime: messageDataToSave.dateTime.toISOString(),
      },
    ]);
    registerChat(
      messageDataToSave,
      user?.institution.concat('_').concat(authorId),
    );
  };

  const handleChangeCpf = (e: any) => {
    const { value } = e.target;
    setCpf(cpfMask(value));
  };

  const getInstitution = async () => {
    let params: any = {
      key: `institutionsFiles/${user?.institution}/logo.png`,
    };
    await getFileFromS3(params).then(res => {
      const data = res ? String(res) : undefined;
      setInstitutions(data);
    });
  };

  useEffect(() => {
    getInstitution();
    createSocket();
    socket.onopen = () => {
      // console.log('WebSocket Client Connected');
    };
    handleGenerateProtocol();
    getManagersData();

    socket.onclose = () => {
      let chatIsOpened = true;
      socket = null;
      if (chatIsOpened) {
        setTimeout(() => {
          createSocket();
        }, 200);
      }
    };
  }, []);

  const sendEmailSolicitation = async (values: any) => {
    console.log({ values, user });
    const reportData = {
      logo: institution,
      numberProtocol: `Protocolo: ${numberProtocol}`,
      data: [
        {
          author: values.nome,
          message: values.message,
          dateTime: new Date().toLocaleString("pt-br"),
        },
      ],
      awsParams: {
        bucketName: 'hml-cartorioslgpd-files',
        key: user.institution,
      },
      fileName: numberProtocol,
    };
    // const reportData = {
    //   logo: logoCurrent ? logoCurrent : false,
    //   numberProtocol: relatedEntities.protocol
    //     ? `Protocolo: ${relatedEntities.protocol}`
    //     : `Relatório do Chat (${relatedEntities.targetName})`,
    //   data: report,
    // };

    // const urlPdf = await generateUrlReport(
    //   reportData,
    //   `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.sa-east-1.amazonaws.com/templates/reportChatMessage.docx`,
    // );
    // const data = {
    //   subject: 'Solicitação Canal de Atendimento',
    //   emailInstitution: user.name,
    //   emailRecipient: values.email,
    //   message: `Um novo protocolo número ${numberProtocol} foi adicionado ao canal de atendimentos.`,
    // };

    const instituion = await getInstitutionById(user.institution);
    const data = {
      recipient: values.email,
      subject: 'Solicitação Canal de Atendimento',
      nome: values.nome,
      protocol: numberProtocol,
      bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
      status: "ABERTO",
      interaction: `${process.env.REACT_APP_SHARE_LINK}interactions/${token.replace("/", "")}`,
    };
    const dataAdm = {
      recipient: "",
      subject: 'Solicitação Canal de Atendimento',
      nome: user.name,
      text: `Foi aberto um novo atendimento com o protocolo ${numberProtocol}`,
      bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
      interaction: `${process.env.REACT_APP_SHARE_LINK_MAIN}support`,
      templateAdm: true
    };
    console.log({dataAdm})
    await sendSolicitationEmail(data);
    const emails = instituion.emailsAlerts.replaceAll("\\s", "").split(",");
    if (emails) {
      emails.forEach(async (value: any) => {
        let req = dataAdm;
        req.recipient = value;
        await sendSolicitationEmail(req);
      });
    }
  };

  const validateCpf = () => {
    const cleanedCpf = cpf.replace(/\D/g, '');
    if (cleanedCpf.length !== 11) {
      setIsValid(false);
      return;
    }
    if (/^(\d)\1+$/.test(cleanedCpf)) {
      setIsValid(false);
      return;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanedCpf.charAt(i)) * (10 - i);
    }
    let firstDigit = 11 - (sum % 11);
    if (firstDigit > 9) {
      firstDigit = 0;
    }
    if (parseInt(cleanedCpf.charAt(9)) !== firstDigit) {
      setIsValid(false);
      return;
    }
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanedCpf.charAt(i)) * (11 - i);
    }
    let secondDigit = 11 - (sum % 11);
    if (secondDigit > 9) {
      secondDigit = 0;
    }
    if (parseInt(cleanedCpf.charAt(10)) !== secondDigit) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  };

  return (
    <>
      <Header title={'Políticas'} institution={institution}>
        <strong>Encarregado de dados:</strong>
        {dpo.length > 0
          ? dpo.map((item: any, index) => (
              <UserInfo key={index}>
                <Separator />
                <br />
                <strong>Nome:</strong> {item.name}
                <br />
                <strong>E-mail:</strong> {item.email}
                <br />
                {item.phone && (
                  <>
                    <strong>Telefone:</strong>{' '}
                    {item.phone.replace(
                      /^(\d{2})(\d{4})(\d{4})$/,
                      '($1) $2-$3',
                    )}
                    <br />
                  </>
                )}
                {item.cellPhone && (
                  <>
                    <strong>Celular:</strong>{' '}
                    {item.cellPhone.replace(
                      /^(\d{2})(\d{5})(\d{4})$/,
                      '($1) $2-$3',
                    )}
                    <br />
                  </>
                )}
              </UserInfo>
            ))
          : ''}
      </Header>
      <Policies />
      <ContainerForm>
        <h1>Canal de Atendimento</h1>
        <Formik
          initialValues={{
            nome: '',
            cpf: '',
            email: '',
            numberProtocol: '',
            typeSolicitation: '',
            institution: '',
            message: '',
            status: '',
            createdAt: '',
          }}
          validate={values => {
            const errors: any = {};
            if (!values.nome) {
              errors.nome = 'Campo não foi preenchido';
              errors.cpf = 'Campo não foi preenchido';
              errors.email = 'Campo não foi preenchido';
              errors.typeSolicitation = 'Campo não foi preenchido';
            }
            return errors;
          }}
          onSubmit={async (values, { resetForm }) => {
            const sendButton: any = document.querySelector("#send-solicitation-button");
            sendButton.setAttribute("disabled", true);
            const dataSend = {
              nome: values.nome,
              cpf: limpaCPF(cpf),
              email: values.email,
              typeSolicitation: values.typeSolicitation,
              message: values.message,
              numberProtocol: numberProtocol,
              institutionId: user?.institution,
              status: 'NOVO',
              createdAt: formatDate(new Date()),
            };
            const alarmData = {
              title: 'Nova Solicitação de Atendimento',
              sourceInstituition: '',
              targetInstitution: user.institution,
              sourceUser: '',
              targetUser: user._id,
              status: 'pendente',
              link: {
                title: 'Canal de atendimento',
                url: '/support',
              },
              type: [
                {
                  description: `Foi aberto um protocolo de número: ${numberProtocol}`,
                  color: 'green',
                  visibleTime: 3000,
                },
              ],
            };
            await api({
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}/alerts/registerAlert`,
              data: alarmData,
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
            }).catch(function (error) {
              console.log(error);
              throw error;
            });
            await api({
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}/support/customer/create`,
              data: dataSend,
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
            })
              .then(response => {
                const solicitation: any = response.data;
                sendEmailSolicitation(values);
                handleSendMessage(
                  solicitation?.insertedId,
                  values?.nome,
                  user?.institution,
                  values?.message,
                );

                setComplete(true);
                openModal();
                
                // resetForm();
              })
              .catch(function (error) {
                console.log(error);
              });
              sendButton.removeAttribute("disabled");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="nome" className="form-label">
                    Nome completo: <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={complete}
                    type="text"
                    className="form-control"
                    id="nome"
                    required
                    value={values?.nome}
                    onChange={handleChange}
                  />
                  {errors.nome && (
                    <div
                      className="input-feedback"
                      style={{
                        fontSize: '10px',
                        color: 'red',
                        marginTop: '10px',
                      }}
                    >
                      {errors.nome}
                    </div>
                  )}
                  <input
                    disabled={complete}
                    type="hidden"
                    className="form-control"
                    id="numberProtocol"
                    required
                    value={numberProtocol}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="cpf" className="form-label">
                    CPF: <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={complete}
                    type="text"
                    className="form-control"
                    id="cpf"
                    required
                    value={cpf}
                    onChange={handleChangeCpf}
                    onBlur={validateCpf}
                  />
                  {!isValid && (
                    <span className={'badge text-bg-danger w-100'}>
                      O CPF informado não é válido.
                    </span>
                  )}
                  {errors.cpf && (
                    <div
                      className="input-feedback"
                      style={{
                        fontSize: '10px',
                        color: 'red',
                        marginTop: '10px',
                      }}
                    >
                      {errors.cpf}
                    </div>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: '42px' }}>
                <div className="mb-3 col-md-6">
                  <label htmlFor="email" className="form-label">
                    E-mail <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={complete}
                    type="email"
                    className="form-control"
                    id="email"
                    required
                    value={values?.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div
                      className="input-feedback"
                      style={{
                        fontSize: '10px',
                        color: 'red',
                        marginTop: '10px',
                      }}
                    >
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="typeSolicitation" className="form-label">
                    Tipo de solicitação <span className="text-danger">*</span>
                  </label>
                  <select
                    disabled={complete}
                    name=""
                    id="typeSolicitation"
                    className="form-control"
                    required
                    value={values?.typeSolicitation}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value={'Confirmação da existência do tratamento'}>
                      Confirmação da existência do tratamento
                    </option>
                    <option value={'Declaração completa dos dados tratados'}>
                      Declaração completa dos dados tratados
                    </option>
                    <option
                      value={
                        'Correção de dados incompletos, inexatos ou desatualizados'
                      }
                    >
                      Correção de dados incompletos, inexatos ou desatualizados
                    </option>
                    <option
                      value={'Bloqueio ou eliminação de dados desnecessários'}
                    >
                      Bloqueio ou eliminação de dados desnecessários
                    </option>
                    <option value={'Revogação do consentimento'}>
                      Revogação do consentimento
                    </option>
                    <option value={'Informações em geral'}>
                      Informações em geral
                    </option>
                    <option
                      value={'Sugestões de aprimoramento relacionadas à LGPD'}
                    >
                      Sugestões de aprimoramento relacionadas à LGPD
                    </option>
                  </select>
                  {errors.typeSolicitation && (
                    <div
                      className="input-feedback"
                      style={{
                        fontSize: '10px',
                        color: 'red',
                        marginTop: '10px',
                      }}
                    >
                      {errors.typeSolicitation}
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-12">
                  <label
                    htmlFor="typeSolicitation"
                    className="form-label"
                    style={{ marginTop: '40px' }}
                  >
                    Escreva sua mensagem ou solicitação:
                  </label>
                  <textarea
                    disabled={complete}
                    className="form-control"
                    id="message"
                    cols={30}
                    rows={5}
                    value={values?.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-md-12">
                  {!complete ? (
                    <button
                      type="submit"
                      id= "send-solicitation-button"
                      className="btn btn-primary"
                      // onClick={() => {
                      //   setFieldValue('numberProtocol', numberProtocol);
                      //   setFieldValue('institutionId', user?.institution);
                      //   setFieldValue('status', 'Novo');
                      //   setFieldValue('createdAt', formatDate(new Date()));
                      // }}
                    >
                      Enviar
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={verProtocolo}
                      className="btn btn-primary"
                      // onClick={() => {
                      //   setFieldValue('numberProtocol', numberProtocol);
                      //   setFieldValue('institutionId', user?.institution);
                      //   setFieldValue('status', 'Novo');
                      //   setFieldValue('createdAt', formatDate(new Date()));
                      // }}
                    >
                      Ver Protocolo
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ContainerForm>

      {/* Modal */}
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h5 className="text-center">Mensagem enviada!</h5>
            <p>O acompanhamento da solicitação deverá ser feito via e-mail!</p>
            <p>
              Protocolo: <strong>{numberProtocol}</strong>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            style={{
              width: '84px',
              borderColor: '#d66464',
              background: '#d66464',
              borderRadius: '12px',
            }}
            onClick={closeModal}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
