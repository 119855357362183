export function generateId() {
    return Math.floor((Date.now() / 1000)).toString(16) + ' '.repeat(16).replace(/./g, () => Math.floor(Math.random() * 16).toString(16))
}

export function dateFormat(data) {
    // Converte a string de data para um objeto Date
    var dateObj = new Date(data);
  
    // Extrai os componentes da data
    var dia = dateObj.getUTCDate();
    var mes = dateObj.getUTCMonth() + 1; // Os meses são indexados de 0 a 11
    var ano = dateObj.getUTCFullYear();
    var horas = dateObj.getHours(); // Obtém a hora ajustada para o fuso horário local
    var minutos = dateObj.getUTCMinutes();
    var segundos = dateObj.getUTCSeconds();
  
    // Formata os componentes da data para o formato desejado
    var dataFormatada = dia + "/" + mes + "/" + ano;
    var horaFormatada = horas + ":" + minutos + ":" + segundos;
  
    // Retorna a data e hora formatadas
    return dataFormatada + " " + horaFormatada;
  }
  