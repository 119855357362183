import jwtDecode from "jwt-decode";

export const decodeUserData = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    let decoded = jwtDecode(user);

    const selectedInstitution = localStorage.getItem(`selected-institution-${decoded._id}`)
    if (selectedInstitution) decoded.institution = selectedInstitution;

    return decoded;
  } else {
    return null;
  }
}

export function getApiToken() {
  const token = localStorage.getItem('token');
  if (token) {
    return token;
  } else {
    return "";
  }  
}