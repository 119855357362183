import { Col, Row } from 'react-bootstrap';
import {
  ChatContainer,
  ContentReply,
  Input,
  InputContainer,
  Message,
  MessageBubble,
  MessageContainer,
  MessagesContainer,
  Title,
} from './style';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import { IMessage } from '../../interfaces/IMessage';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { registerChat } from '../../services/chat-service';
import { dateFormat } from '../../utils/common-functions';
import { IRelatedEntities } from '../../interfaces/IRelatedEntities';
import { IAuthor } from '../../interfaces/IAuthor';
import { useNavigate } from 'react-router-dom';
import {
  downloadFileFromS3,
  uploadFilesToS3,
  getSignedUrlFromS3,
  changeFileName,
} from '../../services/file-service';
import ExportPDF from '../../assets/export.png';
import loadingSVG from '../../assets/loading.svg';
import { exportReport, generateReport } from '../../services/report-service';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react';
import { getChatById } from '../../services/chat-service';

let socket: any = null;

let isAssigned: boolean = false;

interface IChat {
  userData: IAuthor;
  protocol: string;
  room: {};
  chatData: IMessage[];
  relatedEntities: any;
  chatIsOpened: boolean;
  solicit: any;
}

export const ChatMessage = ({
  userData,
  protocol,
  room,
  chatData,
  relatedEntities,
  chatIsOpened,
  solicit
}: IChat) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user')!);

  const createSocket = () => {
    try {
      socket = new W3CWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}`,
        '',
        '',
        {},
        { closeTimeout: 3000, disableNagleAlgorithm: false },
      );
    } catch (err) {
      socket = null;
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(solicit)
    if (chatData) setMessages(chatData);
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setIsBusy(false);
  }, [chatData]);

  useEffect(() => {
    createSocket();
    socket.onopen = () => {
    };

    socket.onmessage = (msg: any) => {
      let receivedMessage = JSON.parse(msg.data);
      if (receivedMessage.relatedEntities.target === relatedEntities.source)
        setMessages(list => [...list, receivedMessage]);
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    socket.onclose = () => {
      socket = null;
      if (chatIsOpened) {
        setTimeout(() => {
          createSocket();
        }, 200);
      }
    };

    let contentElement = $('.chat-body');
    if (!isAssigned) {
      contentElement.on('mousewheel', function (e) {
        e.stopPropagation();
      });
      isAssigned = true;
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = async () => {
    if (inputValue !== '') {
      const messageDataToSave = {
        relatedEntities: relatedEntities,
        author: userData,
        message: inputValue,
        time: new Date().toLocaleString(),
        dateTime: new Date(),
      };

      const messageDataToSend = {
        action: 'send_message',
        data: {
          relatedEntities: relatedEntities,
          author: userData.name,
          message: inputValue.trim(),
          time: new Date().toLocaleString(),
        },
      };
      setTimeout(() => {
        socket.send(JSON.stringify(messageDataToSend));
      }, 11);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          author: messageDataToSave.author,
          message: messageDataToSave.message,
          relatedEntities: messageDataToSave.relatedEntities,
          time: messageDataToSave.time,
          dateTime: messageDataToSave.dateTime.toISOString(),
        },
      ]);
      await registerChat(messageDataToSave, room);
      setInputValue('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
      // setInputValue('');
    }
  };

  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: any) => {
    // nudando o nome no arquivo para evitar duplicidade
    const file = event.target.files[0];
    // const fileUploaded = event.target.files[0];
    let newFileName = changeFileName(file.name);
    const fileUploaded = new File([file], newFileName, { type: file.type });
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_BASE_URL}/files/service-channel-chat-files/${user?.institution}/${fileUploaded.name}`;
    link.textContent = `${fileUploaded.name}`;
    setInputValue(`${link.textContent}`);
    uploadFile(fileUploaded, fileUploaded?.name!);
  };

  const uploadFile = (data: any, id: string) => {
    if (data) {
      let files = [];
      let filesRes = [];
      files.push(data);
      for (const file of files) {
        const params: any = {
          bucketName: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
          fileKey: `service-channel-chat-files/${room}/`,
          fileData: file,
        };
        uploadFilesToS3(params).catch(err => {
          filesRes.push(err.msg);
        });
      }
      return true;
    } else {
      return false;
    }
  };

  const downloadFile = async (e: any) => {
    let params: any = {
      key: `service-channel-chat-files/${room}/${e}`,
      fileName: e,
    };
    downloadFileFromS3(params).then(res => { });
  };

  const checkEmptyFieldsToExportPdf = (data: any, fields: any) => {
    const keys = Object.keys(data);
    for (let field of fields) {
      if (!keys.includes(field)) data[field] = "";
    }
    return data;
  };

  const exportChatFile = async () => {
    setIsBusy(true);

    let params: any = {
      bucketName: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
      key: `institutionsFiles/${user?.institution}/logo.png`,
    };


    let logoCurrent: any = ''
    try {
      logoCurrent = await getSignedUrlFromS3(params);
    } catch (error) {
      console.log(error);
      logoCurrent = '';
    }
    let report: any[] = [];
    const roomData = await getChatById(room);

    const { data }: any = roomData;

    data.messages.forEach((message: any) => {
      report.push({
        author: message.author.name,
        message: message.message,
        dateTime: message.dateTime ? dateFormat(message.dateTime) : "",
      });
    });

    report = report.length
      ? report.map((r) => {
        r = checkEmptyFieldsToExportPdf(r, ["author", "message", "dateTime"]);
        return r;
      })
      : [{ author: "", message: "", dateTime: "" }];

    const chatData = report;
    console.log(relatedEntities)
    const numberProtocol = relatedEntities.protocol
      ? `Protocolo: ${relatedEntities.protocol}`
      : relatedEntities.targetName ? `Relatório do Chat (${relatedEntities.targetName})` : "Relatório do Chat";

    try {
      const templateConfig = {
        modelTemplate: 'chatReportTemplate.docx',
        fileName: 'Relatório do Chat'
      };

      await exportReport({chatData, numberProtocol, logo: logoCurrent}, templateConfig);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Relatório exportado com sucesso!',
        showConfirmButton: false,
        timer: 3000,
      });
      setIsBusy(false);
    } catch (error) {
      console.log(error);
      setIsBusy(false);
    }
  };

  function isFile(str: string): boolean {
    const segments = str.split('.');
    return segments.length > 1 && segments.pop()!.length > 1;
  }

  return (
    <>
      <ChatContainer className={'container'}>
        <Row>
          <Col>
            <span className="text-danger">
              Observação: o prazo de resposta para as solicitações é até 15
              dias.
            </span>
          </Col>
          <Col>
            <div className="col-12 col-md-12 d-flex justify-content-between">
              <label htmlFor=""></label>
              <div
                style={{ marginBottom: '10px', cursor: 'pointer' }}
                onClick={exportChatFile}
              >
                <img src={ExportPDF} alt="export pdf" title="Exportar chat" />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="chat-body" md={12} sm={12}>
            <Title>Mensagem:</Title>
            <MessagesContainer>
              {messages.map((message, index) => (
                <MessageContainer
                  key={index}
                  isOwnerMessage={
                    userData.name === message.author.name ? true : false
                  }
                >
                  <Message
                    isOwnerMessage={
                      userData.name === message.author.name ? true : false
                    }
                  >
                    <MessageBubble
                      isOwnerMessage={
                        userData.name === message.author.name ? true : false
                      }
                    >
                      {isFile(message.message) ? (
                        <div
                          onClick={() => downloadFile(message.message)}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                            color: '#d66464',
                          }}
                          dangerouslySetInnerHTML={{ __html: message.message }}
                        ></div>
                      ) : (
                        message.message
                      )}
                    </MessageBubble>
                    <span>
                      <strong>{message.author.name}</strong> {message.time}
                    </span>
                  </Message>
                </MessageContainer>
              ))}
              <div ref={messageEndRef} />
            </MessagesContainer>
          </Col>
        </Row>
      </ChatContainer>

      {solicit && solicit.status && solicit.status === "FINALIZADO" ? 
        <div className='fw-bolder text-start col-lg-12 p-2'>
          Status:
          <div className='d-inline text-danger px-2'>
            FINALIZADO
          </div>
          <button
              className="btn btn-outline-danger float-end"
              style={{ marginLeft: '20px' }}
              onClick={() => navigate(`/interactions/${token}`)}
            >
              Fechar
            </button>
        </div> : <ChatContainer className={'container'}>
        <Row>
          <Col>
            <Title>Responder:</Title>
            <InputContainer>
              <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </InputContainer>
          </Col>
        </Row>
        <ContentReply>
          <Row>
            <Col>
              <button
                className="btn btn-primary"
                style={{ marginTop: '17px' }}
                onClick={handleClick}
              >
                Inserir Arquivo
              </button>
            </Col>
            <input
              ref={hiddenFileInput}
              onChange={handleChange}
              type="file"
              style={{ display: 'none' }}
            />
          </Row>
          <div
            className="col-12 col-md-12 d-flex justify-content-end"
            style={{ marginTop: '60px' }}
          >
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => handleSendMessage()}
            >
              Enviar
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: '20px' }}
              onClick={() => navigate(`/interactions/${token}`)}
            >
              Cancelar
            </button>
          </div>
        </ContentReply>
      </ChatContainer>}
      {isBusy && (
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={isBusy}
          showIndicator={true}
          indicatorSrc={loadingSVG}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
          message="Gerando arquivo..."
        >
          <img src={loadingSVG} alt="loading..." width={150} height={150} />
        </LoadPanel>
      )}
    </>
  );
};
